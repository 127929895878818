import React from "react"
import Hero from "./Hero"
import ArticlesList from "./ArticlesList"
import { Helmet } from "react-helmet"

const Home = () => {
  return (
    <>
      <Hero />
      <ArticlesList />
    </>
  )
}

export default Home;
