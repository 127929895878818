const articles = [
  {
    "id": 1,
    "slug": "hammentava-testitulos",
    "title": "Hämmentävä testitulos",
    "intro": "Elämän suuret asiat ovat joskus niin suuria, että niitä voi olla vaikea ymmärtää.",
    "coverImage": "",
    "content": `
      <div>
        <p class="mt-8">Olin asuntomme alakerrassa, kun kuulin avovaimoni kutsuvan minua yläkerrasta. Ääni tuntui kuuluvan kaukaa, vaikka asuntomme ei ole kovin suuri. Hänen äänessään oli uudenlainen sävy, jota en ollut kuullut aiemmin. Oli helppo päätellä, että hänellä oli jotain tärkeää kerrottavanaan.</p>

        <p class="mt-8">Riensin yläkertaan, jossa hän tuli minua vastaan raskaustesti kädessään. En muista sanoiko hän mitään vai katsoiko vain silmiini. Joka tapauksessa muistan vain jälkimmäisen. Kun huomasin hänen kädessään olevan raskaustestin, tiesin heti millainen tulos sen näytöllä odottaisi.</p>
        
        <figure class="my-12 -mx-2 sm:mx-0">
          <div class="flex full-width">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/pienokainen-155a4.appspot.com/o/images%2Fpexels-dastan-khdir-16317494-cropped.jpg?alt=media&token=18d8c016-f0fb-472d-bdc1-789f854d1f71"
            width="1280"
            height="720"
            class="aspect-square sm:aspect-video bg-lightFeldgrau object-cover mx-auto"
            alt="Pienokainen image" />
          </div>
          <figcaption class="mt-4 pl-2 sm:pl-0 text-sm text-sage">
            Dastan Khdir / Pexels
          </figcaption>
        </figure>
        
        <p class="mt-8">En ollut ikinä aiemmin nähnyt raskaustestiä muuten kuin paketin kyljestä. Katsoin laitteessa näkyvää viivaa, joka yritti kertoa meille positiivisesta tuloksesta. En tiennyt mitä ajatella ja jähmetyin. <span className="italic">"Voiko tähän näkyyn luottaa, onko tuo hailakka viiva riittävän näkyvä, voiko tulos vielä muuttua, pitäisikö meidän vielä odottaa?"</span> Katsoimme taas toisiamme ja huomasin, ettei hänkään ollut täysin varma mitä ajatella testin tuloksesta. Siinä epäröinnin keskellä haimme vahvistusta toisiltamme ja tietenkin lupaa riemuita hetkestä.</p>
        
        <p class="mt-8">Lyhyen hetken kuluttua halasimme. En muista enää mitä puhuimme halatessamme, mutta muistan huomanneeni äänensävystä kuinka hänkin hymyili. Siinä hetkessä tunsin uudenlaista onnellisuutta, jota en ollut aiemmin kokenut.</p>
        
        <div class="my-12 mx-8">
            <blockquote class="font-bold text-sage text-3xl">
              <p>“Pienen ihmisen on hyvä olla itselleen armollinen elämän suurten asioiden edessä.”</p>
            </blockquote>
        </div>

        <p class="mt-8">Hetkeä myöhemmin olimme lukeneet raskaustestin käyttöohjeen ties kuinka monennen kerran ja uskalsimme olla varmoja testin tuloksesta. Huomasimme olevamme hämmentyneitä ja hieman yllättyneitä, vaikka kyseessä oli pitkään odotettu uutinen. Itse tunsin jopa pientä syyllisyyttä, kun ilo ja riemu eivät olleetkaan päällimmäisinä tunteina. En tätä kirjoittaessani edelleenkään ymmärrä, kuinka pystyin olemaan niin rauhallinen ja syventynyt ajatuksiini. Olen selittänyt itselleni, että kyse oli yksinkertaisesti niin suuresta uutisesta, etten tiennyt kuinka tuntea.</p>
        
        <p class="mt-8">Tästä kaikesta oppineena olen yrittänyt muistuttaa itselleni, että pienen ihmisen on hyvä olla itselleen armollinen elämän suurten asioiden edessä.</p>
      </div>
    `,
    "tags": "",
    "publishedAt": "13.4.2023",
    "visible": true
  },
  {
    "id": 2,
    "slug": "testiartikkeli",
    "title": "Testiartikkeli",
    "intro": "The movie is about wierd things that happen in prison. The movie is about wierd things that happen in prison",
    "coverImage": "",
    "content": `
      <div>
        <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
        
        <p class="mt-8">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
        
        <div class="my-12 mx-8">
            <blockquote class="font-bold text-sage text-3xl">
              <p>“Vel ultricies morbi odio facilisi ultrices accumsan donec lacus purus. Lectus nibh ullamcorper ac dictum justo in euismod. Risus aenean ut elit massa. In amet aliquet eget cras. Sem volutpat enim tristique.”</p>
            </blockquote>
        </div>
        
        <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
        
        <figure class="my-12 -mx-2 sm:mx-0">
          <div class="flex full-width">
          <img class="aspect-square sm:aspect-video bg-lightFeldgrau object-cover mx-auto" src="https://firebasestorage.googleapis.com/v0/b/pienokainen-155a4.appspot.com/o/images%2Funsplash-temp-image.jpeg?alt=media&token=8a70d25c-d3c2-4955-8771-b0421df9035c" alt="Pienokainen image" />
          </div>
          <figcaption class="mt-4 flex px-2 text-sm">
            Faucibus commodo massa rhoncus, volutpat.
          </figcaption>
        </figure>
      </div>
    `,
    "tags": "prison, drama, redemption",
    "publishedAt": "14.4.2023",
    "visible": false
  },
]

export default articles
