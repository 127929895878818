import Navigation from "./Navigation"
import Footer from "./Footer"
import React from "react";
import {Outlet} from "react-router-dom";
const Layout = () => {
  return (
    <div className="flex flex-col min-h-full">
      <Navigation />
      <main className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-[80px] scroll-smooth">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
export default Layout
