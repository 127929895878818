import React from "react"
import {Route, Routes} from "react-router-dom"
import {getFirestore} from "firebase/firestore"
import {FirestoreProvider, useFirebaseApp} from "reactfire"
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check'
import Layout from "./components/Layout"
import Home from "./components/Home"
import NotFound from "./components/NotFound"
import ArticleView from "./components/ArticleView"
import About from "./components/About"
import {appCheckSiteKey} from "./utils";

function App(): JSX.Element {
  const firebaseApp = useFirebaseApp()
  const firestoreInstance = getFirestore(useFirebaseApp());

  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(appCheckSiteKey),
    isTokenAutoRefreshEnabled: true,
  })

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="/:id" element={<ArticleView/>}/>
          <Route path="/tietoa-sivustosta" element={<About/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Routes>
    </FirestoreProvider>
  )
}

export default App
