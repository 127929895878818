// This was for manually created show/hide nav bar on scroll
export const debounce = (fn: any, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const headroomOptions = {
  offset: {
    up: 0,
    down: 0
  },
  // // scroll tolerance in px before state changes
  tolerance : {
    up : 5,
    down : 0
  },
  classes : {
    // when element is initialised
    initial : "headroom animated",
    // when scrolling up (multiple classes are also supported with a space-separated list)
    pinned : "headroom--pinned slideDown",
    // when scrolling down
    unpinned : "headroom--unpinned slideUp",
    // when above offset
    top : "headroom--top",
    // when below offset
    notTop : "headroom--not-top",
    // when at bottom of scroll area
    bottom : "headroom--bottom",
    // when not at bottom of scroll area
    notBottom : "headroom--not-bottom",
    // when frozen method has been called
    frozen: "headroom--frozen",
  },
  // // callback when pinned, `this` is headroom object
  // onPin : function() {},
  // // callback when unpinned, `this` is headroom object
  // onUnpin : function() {},
  // // callback when above offset, `this` is headroom object
  // onTop : function() {},
  // // callback when below offset, `this` is headroom object
  // onNotTop : function() {},
  // // callback when at bottom of page, `this` is headroom object
  // onBottom : function() {},
  // // callback when moving away from bottom of page, `this` is headroom object
  // onNotBottom : function() {}
}

export const appCheckSiteKey = "6LdgCIMlAAAAAFwEocj1VwGeatZ4juFQpZvtjmdV"

export const appCheckSiteKeyLocalDebug = "a2fb1a05-de4a-4272-b925-f017f7dee98e"

export const firebaseConfig = {
  apiKey: "AIzaSyAn_6PGI0t_c13heDxj86WjA6MALdHpt5I",
  authDomain: "pienokainen-155a4.firebaseapp.com",
  projectId: "pienokainen-155a4",
  storageBucket: "pienokainen-155a4.appspot.com",
  messagingSenderId: "299548079426",
  appId: "1:299548079426:web:dc4ba7f71c166239af44db",
  measurementId: "G-5BSK8T5E8N"
}
