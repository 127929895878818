import React, {useEffect} from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <article className="max-w-prose mt-44 mb-10 sm:px-0">
      <h1 className="text-4xl font-bold tracking-tight text-feldgrau sm:text-4xl">Tietoa sivustosta</h1>
      <div className="mt-10 text-base font-normal">
        <p>Pienokainen on kokoelma kirjoituksia perheen kasvun alkuvaiheista.</p>
        <p className="mt-6">
          Ajatus sivuston rakentamiseen syntyi, kun kuuntelimme automatkan aikana <span className="italic font-medium">Juha Itkosen</span> erinomaista kirjaa <span className="italic font-medium">Teoriani perheestä</span>. Juhan tapa kuvata tuntemuksiaan ja perheensä muistoja tuntui niin lämpimältä ja läheiseltä, että päätimme siinä hetkessä panostaa oman perheemme tarinan tallentamiseen.
        </p>
        <p className="mt-6">
          Sivuston tarkoituksena on tallentaa tunteita ja kokemuksia, joita koimme raskauden, syntymän ja niitä seuranneiden ensivaiheiden aikana.
        </p>
        <p className="mt-6">
          Sivusto ei seuraa kävijöitä millään tavoin eikä mitään tietoja tallenneta.
        </p>
      </div>
    </article>
  )
}

export default About
