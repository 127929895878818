import React from "react"
import articles from "../data/articles"
import {Link} from "react-router-dom";
import {HandThumbUpIcon} from "@heroicons/react/24/outline";
import ArticleSocialData from "./ArticleSocialData";

const ArticlesList = () => {
  const visibleArticles = articles
    .filter((article: any) => {
      if (process.env.NODE_ENV === 'development') return true

      return article.visible === true
    })

  return (
    <div>
      {/*<h3 className="text-4xl font-bold tracking-tight text-gray-900">Kirjoitukset</h3>*/}
      {/*<p className="max-w-2xl mt-2 text-lg text-gray-600">Jokainen kirjoitus on merkitty kategorioihin, joiden*/}
      {/*  perusteella voit löytää sinua kiinnostavia aiheita.</p>*/}
      <div id="articlesList" className="mt-20 space-y-20">
        { visibleArticles &&
          visibleArticles.length > 0 &&
          visibleArticles.map((article: any, index: number) => (
            <Link
              to={"/" + article.slug}
              key={article.id + index}
            >
              <div className="flex max-w-xl flex-col items-start justify-between mb-10">
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    {article.title}
                  </h3>
                  <p className="mt-2 text-normal">
                    {article.intro}
                  </p>
                  <div className="flex items-center opacity-50 gap-x-4 text-xs">
                    <time dateTime={article.publishedAt} className="text-raisinBlack">{article.publishedAt}</time>
                    <div className="px-3 py-1.5 font-normal text-raisinBlack space-x-2">{article.tags}</div>
                    <ArticleSocialData articleId={article.id} />
                  </div>
                </div>
              </div>
            </Link>
          ))
        }
        { !visibleArticles ||
          visibleArticles.length === 0 &&
          <div className="flex max-w-xl flex-col items-start justify-between">
            Julkaistuja kirjoituksia ei löytynyt. Palaa myöhemmin uudelleen.
          </div>
        }
      </div>
    </div>
  );
}

export default ArticlesList;
