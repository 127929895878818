import {ChevronDownIcon} from "@heroicons/react/24/outline";

const Hero = () => {
  return (
    <div className="py-16 sm:py-24 lg:flex lg:items-center lg:gap-x-10 lg:py-32 mb-10">
      <div className="max-w-2xl">
        <h1 className="mt-10 text-4xl font-bold tracking-tight sm:text-6xl">
          <span className="text-feldgrau">Pienokainen</span> on kokoelma kirjoituksia perheemme kasvun alkuvaiheista.
        </h1>
        <p className="mt-6 text-lg text-gray-600">
          Sivuston tarkoituksena on tallentaa tunteita ja kokemuksia, joita koimme raskauden, syntymän ja niitä seuranneiden ensivaiheiden aikana. Päätimme pitää kirjoitukset julkisina, jotta niistä voisi olla iloa ja ehkä jopa hyötyä satunnaisille lukijoille.
        </p>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-lightFeldgrau px-4 py-3 text-sm font-semibold text-raisinBlack hover:bg-black-500"
            onClick={() => {
              const articlesList = document.getElementById('articlesList')
              if (articlesList) {
                articlesList.scrollIntoView({behavior: 'smooth'})
              }
            }}
          >
            Siirry lukemaan
            <ChevronDownIcon className="h-5 w-5 text-black" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero
