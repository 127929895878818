import { doc, getFirestore, updateDoc, increment } from 'firebase/firestore'
import { FirestoreProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire'
import {CheckBadgeIcon, HandThumbUpIcon} from "@heroicons/react/24/outline";
import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import {classNames} from "../utils";

const ArticleSocialData = (props: any) => {
  const { articleId } = props

  if (!articleId) return <></>

  const articleRef = doc(useFirestore(), 'articles', articleId.toString());
  const { status, data } = useFirestoreDocData(articleRef);
  const [incremented, setIcremented] = React.useState(false)

  const handleClick = async () => {
    if (incremented) return

    await updateDoc(articleRef, {
      thumbsUp: increment(1),
    })

    setIcremented(true)
  }

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  else if (status === 'error') {
    return <></>
  }

  return (
    <div className="flex flex-row items-center" onClick={handleClick}>
      <HandThumbUpIcon className="h-5 w-5" />
      <span className="ml-2">{ data.thumbsUp }</span>
      <span className={classNames(
        incremented ? "opacity-100" : "",
        "transition-opacity ease-in-out duration-200 opacity-0"
      )}>
        <CheckBadgeIcon className="ml-2 h-5 w-5" />
      </span>
    </div>
  )
}

export default ArticleSocialData
