import {useNavigate, useParams} from "react-router-dom"
import parse, {Element} from 'html-react-parser';
import articles from "../data/articles"
import React, {useEffect} from "react"
import ArticleSocialData from "./ArticleSocialData";
import {Helmet} from "react-helmet";

const parser = (input: string) =>
  parse(input, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs.class === 'remove') {
        return <></>;
      }
    }
  })

const ArticleView = () => {
  const navigate = useNavigate()
  const params = useParams()
  const article = articles
    .filter((article: any) => {
      if (process.env.NODE_ENV === 'development') return true

      return article.visible === true
    })
    .find(article => article.slug === params.id)

  useEffect(() => {
    if (!article) navigate('/')

    window.scrollTo(0, 0);
  }, [article, navigate])

  return (
    <div>
      <Helmet>
        <title>{article?.title} – Pienokainen</title>
        <meta name="description" content={article?.intro} />
        <meta property="og:title" content={article?.title + " – Pienokainen"} />
        <meta property="og:description" content={article?.intro} />
        <meta property="og:image" content="url_to_image" />
        <meta property="og:url" content={"https://pienokainen.fi/" + article?.slug} />
        <meta property="og:site_name" content="Pienokainen" />
        <meta property="og:locale" content="fi_FI" />
        <meta property="og:type" content="article" />
        <meta charSet="utf-8" />
        <link rel="canonical" href={"https://pienokainen.fi/" + article?.slug} />
      </Helmet>
      <div className="absolute top-[80px] left-0 w-full min-h-[400px] flex flex-col justify-center bg-lightAshGray">
        <div className="flex flex-col justify-center max-w-prose mx-auto px-2 sm:px-0">
          <h1 className="text-4xl font-bold tracking-tight text-feldgrau sm:text-4xl">{article?.title}</h1>
          <div className="text-xs font-light">{article?.publishedAt}</div>
          <p className="mt-8 text-xl text-gray-700">{article?.intro}</p>
          <div className="mt-8 opacity-30 hover:opacity-100 transition-opacity ease-in-out duration-200 cursor-pointer">
            <ArticleSocialData articleId={article?.id} />
          </div>
        </div>
      </div>
      <article className="mt-[400px] max-w-prose mb-10 sm:px-0">
        <div className="pt-10 text-base font-normal">
          {parser(article ? article.content : "")}
        </div>
      </article>
    </div>
  )
}

export default ArticleView
