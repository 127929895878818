import React, {useEffect, useState} from 'react'
import Headroom from "headroom.js"
import {Disclosure} from '@headlessui/react'
import {Link} from "react-router-dom"
import {headroomOptions} from "../utils"

const Navigation = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const navElement = document.querySelector("nav")
    if (navElement) {
      const headroomInstance  = new Headroom(navElement, headroomOptions)
      headroomInstance.init()
    }
  }, [])

  return (
    <Disclosure as="nav" className="bg-white navigation">
      {({open}) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="flex h-20 justify-between">
              <div className="flex flex-shrink-0 items-center">
                <Link
                  to="/"
                  className="flex flex-shrink-0 items-center"
                  aria-label="Pienokainen logo"
                >
                  <img
                    className="block h-8 w-auto md:hidden"
                    width="220"
                    height="38"
                    src="images/Pienokainen-logo.svg"
                    alt="Pienokainen logo mobile"
                  />
                </Link>
                <Link
                  to="/"
                  className="flex flex-shrink-0 items-center"
                  aria-label="Pienokainen logo desktop"
                >
                  <img
                    className="hidden h-8 w-auto md:block"
                    width="220"
                    height="38"
                    src="images/Pienokainen-logo.svg"
                    alt="Pienokainen logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}

export default Navigation
