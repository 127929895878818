import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router} from "react-router-dom"
import {FirebaseAppProvider} from "reactfire"
import App from './App'
import {firebaseConfig} from "./utils"
import './index.css'
import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Helmet>
      <title>Pienokainen</title>
      <meta name="description" content="Kokoelma kirjoituksia perheen kasvun alkuvaiheista." />
      <meta property="og:title" content="Pienokainen" />
      <meta property="og:description" content="Kokoelma kirjoituksia perheen kasvun alkuvaiheista." />
      <meta property="og:url" content="https://pienokainen.fi/" />
      <meta property="og:site_name" content="Pienokainen" />
      <meta property="og:locale" content="fi_FI" />
      <meta name="og:keywords" content="raskaus, synnytys, vauva, lapsuus, perhe" />
      <meta name="og:type" content="website" />
      <link rel="canonical" href="https://pienokainen.fi/" />
    </Helmet>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Router>
        <App/>
      </Router>
    </FirebaseAppProvider>
  </React.StrictMode>
)
