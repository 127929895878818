import {Link} from "react-router-dom";
import React from "react";
import {InformationCircleIcon} from "@heroicons/react/24/outline";

const Footer = () => {
  return (
    <footer className="flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-40 text-feldgrau">
      <div className="flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center font-normal text-sm sm:space-x-6">
        <span className="">
          <img
            className="h-4 sm:h-3 mb-1 sm:mb-0 w-auto"
            src="/images/Pienokainen-logo.svg"
            width="82"
            height="12"
            alt="Pienokainen logo"
          />
        </span>
        <span>© {new Date().getFullYear()}</span>
        <span>Kokoelma kirjoituksia perheen kasvun alkuvaiheista.</span>
      </div>
      <div>
        <Link
          to="/tietoa-sivustosta"
          className="flex flex-shrink-0 items-center">
          <button type="button" className="relative inline-flex items-center bg-white sm:px-3 py-4 sm:py-2 text-sm font-normal hover:underline">
            Tietoa sivustosta
          </button>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
